<template>
<div class="stat-box">
  <p class="title is-5 is-paddingless is-marginless">
    {{ '#' + batch.number }}<span class="has-text-weight-light"> • {{ batch.reference }}</span>
  </p>
  <p class="stat-box-percentage has-text-grey is-paddingless is-marginless">{{ batch.created_at | date('L') }} • {{ batch.created_at | fromNow }}</p>

  <p class="title is-2 mt-1" v-if="batch.barcodes_count">
    {{ (batch.barcodes_count - batch.unassigned_barcodes_count) + '/' + batch.barcodes_count }}
    <span class="stat-box-percentage"><span v-if="barcodesLeft > 0 && barcodesLeft < 20">only </span> {{ barcodesLeft }} left</span>
  </p>
  <p class="title is-2 mt-1" v-else>
    {{ batch.size }}
    <span class="stat-box-percentage">codes requested</span>
  </p>
  <span class="is-flex align-center">
    <span class="mr">{{ usage + '%' }}</span>
    <progress class="progress is-small" :class="usageClass" :value="usage" max="100">{{ usage }}%</progress>
  </span>
</div>  
</template>

<script>
export default {
  props: {
    batch: {
      type: Object,
      default: () => ({
        unassigned_barcodes_count: 0,
        barcodes_count: 0
      })
    }
  },

  computed: {
    barcodesLeft() {
      return this.batch.barcodes_count - (this.batch.barcodes_count - this.batch.unassigned_barcodes_count)
    },
    usage() {
      if(!this.batch.barcodes_count) return 0
      if(this.barcodesLeft === 0) return 100
      if(this.batch.barcodes_count === this.batch.unassigned_barcodes_count) return 0
      if(this.batch.barcodes_count && this.batch.unassigned_barcodes_count > 0) return (((this.batch.barcodes_count - this.batch.unassigned_barcodes_count) / this.batch.barcodes_count) * 100).toFixed(1)
      return 0
    },
    usageClass() {
      if(this.usage >= 75) return 'is-danger'
      if(this.usage >= 50) return 'is-warning'
      if(this.usage < 50) return 'is-success'
    }
  }
}
</script>