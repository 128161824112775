<template>
  <div class="selected-item cursor-pointer" @click="editCheck">
    <icon icon="grip-vertical" id="handle"/>
    <span class="tag is-sort-number is-cool-blue-dark is-medium is-rounded mr-1">{{ index + 1 }}</span>
    <div class="grid has-8-columns gap-1 flex-grow align-center">
      <div class="is-5-columns is-flex flex-column">
        <span class="has-text-weight-bold">{{ check.name }}</span>
        <span class="has-text-cool-blue-text">{{ check.task | truncate(100) }}</span>
      </div>
      <div class="is-flex align-center justify-end">
        <span class="tag is-rounded is-warning has-text-weight-bold has-tooltip-top" data-tooltip="If this check fails, the entire inspection fails immediately." v-if="check.instant_fail">Instant Fail</span>
      </div>
      <div class="tags has-addons is-one-line has-tooltip-top" data-tooltip="Image Rule">
        <span class="tag is-icon is-rounded is-cool-blue-dark has-text-cool-blue-text-dark has-text-weight-medium"><icon icon="camera"/></span>
        <span class="tag is-rounded flex-grow is-cool-blue">{{ check.image_rule | startCase }}</span>
      </div>
      <div class="tags has-addons is-one-line tooltip has-tooltip-top" data-tooltip="Comment Rule">
        <span class="tag is-icon is-rounded is-cool-blue-dark has-text-cool-blue-text-dark has-text-weight-medium"><icon icon="comment"/></span>
        <span class="tag is-rounded flex-grow is-cool-blue">{{ check.comment_rule | startCase }}</span>
      </div>
    </div>
    <a v-show="canDelete" :disabled="isLocked" class="delete is-medium ml-1" @click.stop="$emit('onRemoveCheck', index)"/>
  </div>
</template>

<script>
import startCase from 'lodash/startCase'
import { editCheck } from '@/modals'

export default {

  filters: {
    startCase
  },

  props: {
    check: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isLocked: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    async editCheck() {
      this.$store.commit('check/setCheck', this.check)
      const isChanged = await editCheck();
      this.$emit('onCheckUpdated', isChanged, this.check.id);
    }
  }

}
</script>
