<template>
  <page :title="`New ${Naming.Client}`">
    <div slot="tools">
      <router-link class="button is-cool-blue is-rounded" :to="{ name: 'client-admin-index' }">
        <icon icon="arrow-left"/>
        <span>{{ Naming.Clients }}</span>
      </router-link>
    </div>

    <form>
      <div class="box">
        <div class="columns">
          <div class="column">
            <text-input 
              :error="$root.errors.legal_name" 
              :value="client.legal_name" 
              @input="legalName">
              Legal Name
            </text-input>
          </div>
          <div class="column">
            <text-input :error="$root.errors.trading_name" :value="client.trading_name" @input="tradingName">Trading Name</text-input>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <text-input :error="$root.errors.registration_number" :value="client.registration_number" @input="registrationNumber">Registration Number</text-input>
          </div>
          <div class="column">
            <text-input :error="$root.errors.vat_number" :value="client.vat_number" @input="vatNumber">Vat Number</text-input>
          </div>
        </div>

        <physical-address @input="setAddress" />
        
      </div>
      <div class="columns">
        <div class="column">
          <submit-button 
            :disabled="$root.hasAbility('create-clients', 'Billow\\Models\\Client') === false"
            class="is-success is-rounded" 
            :working="working" 
            left-icon="check" 
            @submit="createClient">Create {{ Naming.Client }}</submit-button>
        </div>
      </div>
    </form>

  </page>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false
  }),

  beforeCreate() {
    this.$store.commit('client/clearClient')
  },

  methods: {
    ...mapMutations('client', [
      'legalName',
      'tradingName',
      'registrationNumber',
      'vatNumber',
      'setAddress'
    ]),
    createClient() {
      this.working = true
      this.$store.dispatch('client/create').then(client => {
        this.working = false
        this.$toast.success(this.Convert(this.$lang.client.created))
        this.$router.push({
          name: 'client-overview',
          params: {
            client: client.id
          }
        })
      }).catch(error => {
        this.working = false
        this.$whoops()
      })
    }
  },

  computed: {
    ...mapGetters('client', [
      'client'
    ])
  }
}
</script>
