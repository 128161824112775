<template>
<loader v-bind="{ loading }">
  <div class="box">
    <level :title="`Deleted ${Naming.Sites}`">
    </level>

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          <th class="is-narrow">Created</th>
          <th class="is-narrow">Deleted</th>
          <th class="has-text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="site in sites.data" :key="site.id">
          <td>
            {{ site.name }}
          </td>
          <td class="is-narrow">
            {{ site.created_at | date('L LTS') }}
          </td>
          <td class="is-narrow">
            {{ site.deleted_at | date('L LTS') }}
          </td>
          <td class="has-text-right">
            <action-button :disabled="recovering" @click="recoverSite(site)" class="is-rounded is-small">
              Recover {{ Naming.Site }}
            </action-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="columns">
      <div class="column">
        <pager :pageable="sites" @nav="loadSites" :context="Naming.Site.toLowerCase()"/>
      </div>
    </div>
  </div>
</loader>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixins, utilities } from '@/mixins/js-helpers'

export default {

  mixins: [mixins.filterBy],

  name: 'client-deleted-sites',

  data: () => ({
    loading: true,
    recovering: false,
    namespace: 'site',
    path: '',
    filters: {
      search: ''
    }
  }),

  async created() {
    this.path = `/api/client/${this.$route.params.client}/sites/deleted`
    await this.loadSites(this.path + utilities.cleanQuery(this.filters))
    this.loading = false
  },

  methods: {
    loadSites(path) {
      return this.$store.dispatch(
        'site/loadSitesByClient',
        path
      )
    },
    searchSites() {
      this.$store.dispatch(
        'site/loadSitesByClient',
        this.path + utilities.cleanQuery(this.filters)
      )
      this.$router.push({
        name: this.$options.name,
        query: this.filters
      })
    },
    recoverSite(site) {
      this.$toast.info('Recovery started.')
      this.recovering = true
      this.$store.dispatch('site/recoverSite', site.id).then(() => {
        this.recovering = false
        this.$toast.success(this.Convert('The site has been successfully recovered.'))
        this.$router.push({
          name: 'client-sites',
          params: {
            client: this.$route.params.client
          }
        })
      }).catch(error => {
        this.recovering = false
        this.$toast.error('Whoops something went wrong, please contact support.')
      })
    }
  },

  computed: {
    ...mapGetters('client', [
      'client'
    ]),
    ...mapGetters('site', [
      'sites'
    ])
  }

}
</script>
