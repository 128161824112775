<template>
  <div class="level">
    <slot name="left" v-if="!title"></slot>
    <div class="level-left" v-if="title">
      <h1 class="title is-4 level-item">
        <icon v-if="titleIcon && title" class="mr" :icon="titleIcon"/>
        <span>{{ title }}</span>
      </h1>
      <slot name="left"></slot>
    </div>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    titleIcon : String,
  }
}
</script>
