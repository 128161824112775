<template>
  <div>
    <div class="is-flex align-center mb">
      <div class="flex-shrink is-flex align-center">
        <div class="has-text-bold progress-label">
          Critical
        </div>
        <span class="ml">{{ inversePercentage(counts.critical.percentage_of_total) }}%</span>
      </div>
      <div class="flex-grow is-flex align-center pl">
        <progress class="progress is-marginless" :class="{
        'is-success': inversePercentage(counts.critical.percentage_of_total) === 100,
        'is-danger': inversePercentage(counts.critical.percentage_of_total) < 100,
      }" :value="inversePercentage(counts.critical.percentage_of_total)" max="100">{{ inversePercentage(counts.critical.percentage_of_total) }}%
        </progress>
        <div class="ml">
        <span class="tag" :class="{
          'is-success': inversePercentage(counts.critical.percentage_of_total) === 100,
          'is-danger': inversePercentage(counts.critical.percentage_of_total) < 100,
        }">
          <icon :icon="counts.critical.count > 0 ? 'exclamation-triangle' : 'check-circle'"/>
          <span>{{ counts.critical.count }}</span>
        </span>
        </div>
      </div>
    </div>
    <div class="is-flex align-center mb">
      <div class="flex-shrink is-flex align-center">
        <span class="has-text-bold progress-label">High</span>
        <span class="ml">{{ inversePercentage(counts.high.percentage_of_total) }}%</span>
      </div>
      <div class="flex-grow is-flex align-center pl">
        <progress class="progress is-marginless" :class="{
          'is-success': inversePercentage(counts.high.percentage_of_total) === 100,
          'is-warning': inversePercentage(counts.high.percentage_of_total) < 100,
          'is-danger': inversePercentage(counts.high.percentage_of_total) < 80,
        }" :value="inversePercentage(counts.high.percentage_of_total)" max="100">{{ inversePercentage(counts.high.percentage_of_total) }}%
        </progress>
        <div class="ml">
        <span class="tag" :class="{
          'is-success': inversePercentage(counts.high.percentage_of_total) === 100,
          'is-warning': inversePercentage(counts.high.percentage_of_total) < 100,
          'is-danger': inversePercentage(counts.high.percentage_of_total) < 80,
        }">
          <icon :icon="counts.high.count > 0 ? 'exclamation-triangle' : 'check-circle'"/>
          <span>{{ counts.high.count }}</span>
        </span>
        </div>
      </div>
    </div>
    <div class="is-flex align-center mb">
      <div class="flex-shrink is-flex align-center">
        <span class="has-text-bold progress-label">Medium</span>
        <span class="ml">{{ inversePercentage(counts.medium.percentage_of_total) }}%</span>
      </div>
      <div class="flex-grow is-flex align-center pl">
        <progress class="progress is-marginless" :class="{
          'is-success': inversePercentage(counts.medium.percentage_of_total) === 100,
          'is-warning': inversePercentage(counts.medium.percentage_of_total) < 100,
          'is-danger': inversePercentage(counts.medium.percentage_of_total) < 80,
        }" :value="inversePercentage(counts.medium.percentage_of_total)" max="100">{{ inversePercentage(counts.medium.percentage_of_total) }}%
        </progress>
        <div class="ml">
        <span class="tag" :class="{
          'is-success': inversePercentage(counts.medium.percentage_of_total) === 100,
          'is-warning': inversePercentage(counts.medium.percentage_of_total) < 100,
          'is-danger': inversePercentage(counts.medium.percentage_of_total) < 80,
        }">
          <icon :icon="counts.medium.count > 0 ? 'exclamation-triangle' : 'check-circle'"/>
          <span>{{ counts.medium.count }}</span>
        </span>
        </div>
      </div>
    </div>
    <div class="is-flex align-center">
      <div class="flex-shrink is-flex align-center">
        <span class="has-text-bold progress-label">Low</span>
        <span class="ml">{{ inversePercentage(counts.low.percentage_of_total) }}%</span>
      </div>
      <div class="flex-grow is-flex align-center pl">
        <progress class="progress is-marginless" :class="{
          'is-success': inversePercentage(counts.low.percentage_of_total) === 100,
          'is-warning': inversePercentage(counts.low.percentage_of_total) < 100,
          'is-danger': inversePercentage(counts.low.percentage_of_total) < 80,
        }" value="100" max="100">100%
        </progress>
        <div class="ml">
        <span class="tag" :class="{
            'is-success': inversePercentage(counts.low.percentage_of_total) === 100,
            'is-warning': inversePercentage(counts.low.percentage_of_total) < 100,
            'is-danger': inversePercentage(counts.low.percentage_of_total) < 80,
          }">
          <icon :icon="counts.low.count > 0 ? 'exclamation-triangle' : 'check-circle'"/>
          <span>{{ counts.low.count }}</span>
        </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {subtract} from "lodash";

export default {
  props: {
    counts: {
      type: Object,
      default: () => ({
        critical: {},
        High: {},
        medium: {},
        low: {},
      })
    },
  },

  methods: {
    inversePercentage(percentage) {
      return subtract(100, percentage);
    }
  },
}
</script>