<template>
  <page :title="Naming.Checklists" icon="clipboard" v-bind="{ loading }" :loading-text="`Loading ${Naming.Checklists}…`">
    <div slot="tools">
      <div class="buttons has-addons is-rounded is-right">
        <action-button
          slot="trigger"
          class="is-rounded"
          :working="isDownloadingCheckpoints"
          @click="downloadCheckpoints"
          left-icon="download">
          {{ Naming.Checks }} (.xlsx)
        </action-button>
        <router-link
          v-if="$root.hasAbility('manage-checklists', 'Billow\\Models\\Job')"
          class="button is-rounded is-primary"
          :to="{ name: 'checklist-create' }">
          <icon icon="plus"/>
          <span>{{ Naming.Checklist }}</span>
        </router-link>
      </div>
    </div>

    <div class="box is-paddingless">

      <form class="p">
        <text-input
          v-model="search"
          class="m-1"
          classes="is-rounded is-medium"
          :placeholder="`Find a ${Naming.Checklist.toLowerCase()}…`">
          <div class="buttons has-addons" slot="right">
            <action-button class="is-rounded is-medium" @click="runSearch" left-icon="search">
              Search
            </action-button>
            <action-button class="is-rounded is-medium" @click="clearSearch" v-if="search.length">
              <icon icon="times"/>
            </action-button>
          </div>
        </text-input>
      </form>

      <pager
        class="p"
        :pageable="checklists"
        @nav="goToPage"
        jump-controls
        :context="Naming.Checklist.toLowerCase()"
      />

      <table class="table is-fullwidth is-hoverable">
        <thead class="is-sticky">
        <tr>
          <th>Name</th>
          <th>Frequency</th>
          <th>{{ Naming.AssetTypes }}</th>
          <th>{{ Naming.Checks }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="checklist in checklists.data" :key="checklist.id">
          <td>
            <router-link v-if="$root.hasAbility('manage-checklists', 'Billow\\Models\\Job')" class="is-block"
              :to="{ name: 'checklist-edit', params: { checklist: checklist.id }}">
              <span>{{ checklist.name }}</span>
              <span class="is-small has-text-grey" v-if="checklist.report_layout">&nbsp;&nbsp;&nbsp;Custom Report</span>
              <icon icon="lock" class="has-text-danger" v-if="checklist.locked"/>
            </router-link>
            <span v-else>
              <span>{{ checklist.name }}</span>
              <icon icon="lock" class="has-text-danger" v-if="checklist.locked"/>
            </span>
          </td>
          <td>
            <span v-if="checklist.frequency > 0">{{ checklist.frequency + ' days' }}</span>
            <span v-else>Does not repeat</span>
          </td>
          <td>{{ checklist.asset_types_count || 0 }}</td>
          <td>{{ checklist.checks_count || 0 }}</td>
        </tr>
        </tbody>
      </table>
      <p class="has-text-centered p-2" v-if="!checklists.data.length">
        No {{ Naming.Checklists.toLowerCase() }} found
      </p>
      <pager
        class="p"
        :pageable="checklists"
        @nav="goToPage"
        jump-controls
        :context="Naming.Checklist.toLowerCase()"
      />
    </div>
  </page>
</template>

<script>
import { mapGetters } from 'vuex'
import common from '@/api/common'
import download from 'downloadjs'

export default {

  data: () => ({
    loading: true,
    search: '',
    isDownloadingCheckpoints: false,
  }),

  async created() {
    await this.loadchecklists()
    this.loading = false
  },

  methods: {
    loadchecklists() {
      this.$scrollToTop()
      return this.$store.dispatch('checklist/loadChecklists', {
        path: '/api' + this.$route.fullPath,
        params: this.$route.query
      })
    },
    runSearch() {
      this.$router.push({
        name: 'checklist-index',
        query: { search: this.search }
      })
    },
    clearSearch() {
      this.search = ''
      this.$router.push({
        name: 'checklist-index',
        query: {}
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    downloadCheckpoints() {
      this.isDownloadingCheckpoints = true

      common.downloadFile(`/api/checks/download/xlsx`, ({ data }) => {
        download(data, 'checkpoints.xlsx')
        this.$toast.success(this.Convert('Downloaded checkpoints.'))
        this.isDownloadingCheckpoints = false
      }, error => {
        this.$toast.error('Whoops. Something went wrong, please try again.')
        this.isDownloadingCheckpoints = false
      })
    }
  },

  computed: {
    ...mapGetters('checklist', [
      'checklists'
    ])
  },

  watch: {
    '$route': 'loadchecklists'
  }

}
</script>
