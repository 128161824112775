<template>
  <div class="version-box" @click="showingChecks = !showingChecks">
    <div class="is-flex align-items-center">
      <span class="version-number">V{{ version.version }}</span>
      <div class="ml-1">
        <p class="has-text-weight-medium" v-if="version.createdBy !== ' '">Modified by {{ version.createdBy }}</p>
        <p class="has-text-weight-medium" v-else>Original</p>
        <p class="has-text-grey-light is-size-7">{{ version.createdAt | date('L LTS') }}</p>
      </div>
      <div class="ml-1 mb-1 is-flex align-items-start">
        <p class="component-tag parent has-text-weight-semibold m-0"> {{ version.checks.length }} {{
            Naming.Checks
          }}</p><br>
        <p class="component-tag current-version has-text-weight-semibold ml-1" v-if="isCurrentVersion"> Current
          Version</p>
      </div>
    </div>
    <div v-if="showingChecks">
      <div class="mt-1" v-if="version.changeLog">

        <div class="span" :class="{'is-changed': version.changeLog?.previous.name !== version.changeLog?.next.name}">
          <span class="label-header"><b>Name:  </b></span>
          <span style="color: grey">
                  {{ version.changeLog?.next.name }}
                  <span v-if="version.changeLog?.previous.name !== version.changeLog?.next.name"
                        :data-tooltip="`From ${ version.changeLog?.previous.name } to ${ version.changeLog?.next.name }`">
                    <i class="fas fa-exclamation-circle"></i>
                  </span>
                </span>
        </div>

        <div class="span"
             :class="{'is-changed': version.changeLog?.previous.frequency !== version.changeLog?.next.frequency}">
          <span class="label-header"><b>Frequency:  </b></span>
          <span style="color: grey">
                  {{ version.changeLog?.next.frequency }}
                  <span v-if="version.changeLog?.previous.frequency !== version.changeLog?.next.frequency"
                        :data-tooltip="`From ${ version.changeLog?.previous.frequency } to ${ version.changeLog?.next.frequency }`">
                    <i class="fas fa-exclamation-circle"></i>
                  </span>
          </span>
        </div>

        <div class="span"
             :class="{'is-changed': version.changeLog?.previous.pass_percentage !== version.changeLog?.next.pass_percentage}">
          <span class="label-header"><b>Pass Percentage:  </b></span>
          <span style="color: grey">
                  {{ version.changeLog?.next.pass_percentage }}
                  <span v-if="version.changeLog?.previous.pass_percentage !== version.changeLog?.next.pass_percentage"
                        :data-tooltip="`From ${ version.changeLog?.previous.pass_percentage } to ${ version.changeLog?.next.pass_percentage }`">
                    <i class="fas fa-exclamation-circle"></i>
                  </span>
          </span>
        </div>

        <div class="span"
             :class="{'is-changed': version.changeLog?.previous.requires_signature !== version.changeLog?.next.requires_signature}">
          <span class="label-header"><b>Technician Signature:  </b></span>
          <span style="color: grey">
                  {{ version.changeLog?.next.requires_signature }}
            <span v-if="version.changeLog?.previous.requires_signature !== version.changeLog?.next.requires_signature"
                  :data-tooltip="`From ${ version.changeLog?.previous.requires_signature } to ${ version.changeLog?.next.requires_signature }`">
                    <i class="fas fa-exclamation-circle"></i>
            </span>
          </span>
        </div>

        <div class="span"
             :class="{'is-changed': version.changeLog?.previous.requires_approver_signature !== version.changeLog?.next.requires_approver_signature}">
          <span class="label-header"><b>Approval Signature:  </b></span>
          <span style="color: grey">
                  {{ version.changeLog?.next.requires_approver_signature }}
                        <span
                            v-if="version.changeLog?.previous.requires_approver_signature !== version.changeLog?.next.requires_approver_signature"
                            :data-tooltip="`From ${ version.changeLog?.previous.requires_approver_signature } to ${ version.changeLog?.next.requires_approver_signature }`">
                    <i class="fas fa-exclamation-circle"></i>
            </span>
          </span>
        </div>

        <div class="span"
             :class="{'is-changed': version.changeLog?.previous.inspection_report_images_inline !== version.changeLog?.next.inspection_report_images_inline}">
          <span class="label-header"><b>Inline Images:  </b></span>
          <span style="color: grey">
                  {{ version.changeLog?.next.inspection_report_images_inline }}
                        <span
                            v-if="version.changeLog?.previous.inspection_report_images_inline !== version.changeLog?.next.inspection_report_images_inline"
                            :data-tooltip="`From ${ version.changeLog?.previous.inspection_report_images_inline } to ${ version.changeLog?.next.inspection_report_images_inline }`">
                    <i class="fas fa-exclamation-circle"></i>
            </span>
                </span>
        </div>
      </div>
      <div v-if="!version.changeLog">
        <div class="span mt-1">
          <span class="label-header"><b>Name:  </b></span>
          <span style="color: grey">{{ checklist.name }}</span>
        </div>
        <div v-if="!version.changeLog" class="span">
          <span class="label-header"><b>Frequency:  </b></span>
          <span style="color: grey">{{ checklist.frequency }}</span>
        </div>
        <div class="span">
          <span class="label-header"><b>Pass Percentage:  </b></span>
          <span style="color: grey">{{ checklist.pass_percentage }}</span>
        </div>
        <div class="span">
          <span class="label-header"><b>Technician Signature:  </b></span>
          <span style="color: grey">{{ checklist.requires_signature }}</span>
        </div>
        <div class="span">
          <span class="label-header"><b>Approval Signature:  </b></span>
          <span style="color: grey">{{ checklist.requires_approver_signature }}</span>
        </div>
        <div class="span">
          <span class="label-header"><b>Inline Images:  </b></span>
          <span style="color: grey">{{ checklist.inspection_report_images_inline }}</span>
        </div>
      </div>

      <table class="table is-fullwidth is-vcentered mt-1">
        <thead>
        <tr>
          <td class="is-narrow">Order</td>
          <td class="">{{ Naming.Check }} Name</td>
          <td class="is-narrow">Changes</td>
        </tr>
        </thead>
        <tbody>
        <tr class="" v-for="(check, index) in version.check_changes" :key="check.id">
          <td :class="getRowClass(check.changeType)"><span class=""> {{ check.sort + 1 }}</span></td>
          <td class="is-3-columns" :class="getRowClass(check.changeType)">
            {{ check.name }} <span v-if="check.deleted_at" class="component-tag deleted-tag">Deleted</span></td>
          <td class="is-1-columns" :class="getRowClass(check.changeType)">
              <span class="icon" :data-tooltip="`${getToolTip(check.changeType, check.change_meta, check.sort + 1)}`">
                <i :class="getIcon(check.changeType, check.sort + 1, check.change_meta)"></i>
                <span v-if="check.changeType === 'changed'">{{ check.change_meta }}</span>
              </span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {

  name: 'checklist-check-version',

  props: {
    version: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showingChecks: false
  }),

  methods: {
    getRowClass(changeType) {
      switch (changeType) {
        case 'added':
          return 'is-added';
        case 'changed':
          return 'is-changed';
        case 'deleted':
          return 'is-deleted';
        default:
          return '';
      }
    },
    getToolTip(changeType, from, to) {
      switch (changeType) {
        case 'added':
          return 'Added Check';
        case 'changed':
          return `Moved from ${from} to ${to}`;
        case 'deleted':
          return 'Removed Check';
        default:
          return '';
      }
    },
    getIcon(changeType, index, sort) {
      switch (changeType) {
        case 'added':
          return 'fas fa-plus-circle'; // Icon for added
        case 'changed':
          return index < sort ? 'fas fa-arrow-up' : 'fas fa-arrow-down is-danger'; // Icon for changed
        case 'deleted':
          return 'fas fa-minus-circle'; // Icon for deleted
        default:
          return '';
      }
    }
  },

  computed: {
    ...mapState('checklist', ['checklist']),
    isCurrentVersion() {
      return this.version.version === this.checklist.current_checklist_version
    }
  },

}
</script>
<style scoped>
.label-header {
  color: #5d5d5d
}

.is-added {
  background-color: rgba(212, 237, 218, 0.5); /* Light green for added */
}

.is-changed {
  background-color: rgba(255, 243, 205, 0.5); /* Light yellow for changed */
}

.is-deleted {
  background-color: rgba(248, 215, 218, 0.5); /* Light red for deleted */
}

.is-unchanged {
  background-color: #e2e3e5; /* Light gray for unchanged */
}
</style>