<template>
<div>
  <div class="columns">
    <div class="column">
      <form class="box">
        <div class="columns">
          <div class="column">
            <text-input :value="client.legal_name" @input="legalName">Legal Name</text-input>
          </div>
          <div class="column">
            <text-input :value="client.trading_name" @input="tradingName">Trading Name</text-input>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <text-input :value="client.registration_number" @input="registrationNumber">Registration Number</text-input>
          </div>
          <div class="column">
            <text-input :value="client.vat_number" @input="vatNumber">Vat Number</text-input>
          </div>
        </div>

        <physical-address @input="setAddress" :addr="client.address" />

        <div class="columns mt-2">
          <div class="column">
            <submit-button class="is-success" :working="working" left-icon="check" @submit="save">Save</submit-button>
          </div>
          <div class="column has-text-right">
            <action-button :disabled="!$root.hasAbility('delete-clients', 'Billow\\Models\\Client')" class="is-danger" :working="working" left-icon="trash" @click="deleteClient">Delete</action-button>
          </div>
        </div>
      </form>
    </div>
    <div class="column is-3">
      <div class="box">
        <h2 class="box-title">
          {{ Naming.Client }} Logo
        </h2>        
        <img @click="openImageUploader" :src="client.logo_url" v-if="client.logo_url">
        <p v-else>A logo hasn’t been uploaded for this {{ Naming.Client.toLowerCase() }} yet.</p>
        <div class="is-flex justify-between mt-1">
          <action-button 
            @click="openImageUploader"
            left-icon="upload"
            class="is-primary is-small is-rounded mt-1">
            Upload Logo
          </action-button>
          <action-button 
            v-if="client.logo_url" 
            @click="reset"
            left-icon="redo-alt"
            class="is-danger is-small is-rounded mt-1"
            :working="uploading">
            Reset Logo
          </action-button>
        </div>       
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { uploadClientLogo } from '@/modals'

export default {

  data: () => ({
    working: false,
    uploading: false
  }),

  methods: {
    ...mapMutations('client', [
      'legalName',
      'tradingName',
      'registrationNumber',
      'vatNumber',
      'setAddress'
    ]),
    openImageUploader() {
      uploadClientLogo()
    },
    reset() {
      this.uploading = true
      this.$store.dispatch('client/resetClientLogo').then(() => {
        this.uploading = false
        this.$toast.success(this.Convert('Success', 'The Client logo has been reset.'))
      })
    },
    save() {
      this.working = true
      this.$store.dispatch('client/save').then(() => {
        this.working = false
        this.$toast.success(this.Convert(this.$lang.client.saved))
      }).catch(error => {
        this.working = false
        this.$whoops()
      })
    },
    async deleteClient() {
      const {title, message, confirmText} = this.$lang.client.confirmDelete
      const convertedTitle = await this.Convert(title)
      const convertedMessage = await this.Convert(message)
      const convertedConfirmText = await this.Convert(confirmText)
      if (await this.$confirm({
        title: convertedTitle,
        message: convertedMessage,
        confirmText: convertedConfirmText,
        confirmClass: 'is-danger',
      })) {
        this.working = true
        this.$store.dispatch('client/delete').then(() => {
          this.working = false
          this.$toast.success('Delete request in progress. You will receive an email upon completion.')
          this.$router.push({name: 'client-admin-index'})
        }).catch(error => {
          this.working = false
          console.error(error)
          this.$whoops()
        })
      }
    },
  },

  computed: {
    ...mapGetters('client', [
      'client'
    ])
  }
}
</script>
