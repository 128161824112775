<template>
  <base-modal :title="`${Naming.Checklist} Tools`">
    <div v-if="!loading">
      <form class="mb-2">
        <div class="columns">
          <div class="column">
            <data-selector
                multiple
                searchable
                delete-buttons
                tag-class="is-info"
                :value="checklist.asset_types"
                :items="assetTypeList"
                label-key="name"
                value-key="id"
                @input="updateAssetTypes"
            >{{ Naming.AssetTypes }}
            </data-selector
            >
          </div>
          <div class="column is-narrow is-flex align-end">
            <submit-button
                :working="syncing"
                class="is-success"
                left-icon="check"
                @submit="syncAssetTypes"
            >Save
            </submit-button
            >
          </div>
        </div>
      </form>

      <h5 class="is-size-5 has-text-dark mb-1">Additional Tools </h5>

      <div class="columns is-align-items-flex-end">
        <div class="column">
          <p class="mb">Require a signature for this {{ Naming.Checklist.toLowerCase() }}.</p>
          <switch-input
              classes="is-medium"
              :value="checklist.requires_signature"
              @input="toggleSignatureRequirement"
          >
          </switch-input>
        </div>
        <div class="column">
          <p class="mb">Duplicate the {{ Naming.Checklist.toLowerCase() }} with {{ Naming.Checks.toLowerCase() }},
            keeping the {{ Naming.AssetTypes.toLowerCase() }} on the original {{ Naming.Checklist.toLowerCase() }}.</p>
          <action-button
              :working="duplicating"
              @click="duplicateChecklist(false)"
              class="is-primary"
              left-icon="copy"
          >Duplicate
          </action-button
          >
        </div>
      </div>
      <div class="columns is-align-items-flex-end">
        <div class="column">
          <p class="mb">Require an approver signature for this {{ Naming.Checklist.toLowerCase() }}.</p>
          <switch-input
              classes="is-medium"
              :value="checklist.requires_approver_signature"
              @input="toggleApproverSignatureRequirement"
          >
          </switch-input>
        </div>
        <div class="column">
          <p class="mb">Download {{ Naming.Checklist.toLowerCase() }} as an excel document.</p>
          <action-button
              :working="downloading"
              @click="downloadAsExcel"
              left-icon="download"
          >Download
          </action-button
          >
        </div>
        <div class="column">
          <p class="mb" v-if="!checklist.inspections_count">
            Delete the {{ Naming.Checklist.toLowerCase() }}.
          </p>
          <p class="mb" v-else>This {{ Naming.Checklist.toLowerCase() }} has an {{ Naming.Inspection.toLowerCase() }}
            history.</p>
          <action-button
              @click="deleteChecklist"
              :disabled="checklist.inspections_count > 0 || duplicating"
              class="is-danger"
              left-icon="trash"
          >Delete
          </action-button
          >
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import download from "downloadjs";
import backend from "@/api/common";

export default {
  data: () => ({
    duplicating: false,
    downloading: false,
    loading: true,
    syncing: false,
  }),

  beforeCreate() {
    this.$store.dispatch("assetType/loadAssetTypeList").then(() => {
      this.loading = false;
    });
  },

  methods: {
    ...mapMutations("checklist", [
      "toggleSignatureRequirement",
      "toggleApproverSignatureRequirement",
      "updateAssetTypes",
    ]),
    duplicateChecklist(detach) {
      this.duplicating = true;
      this.$store
          .dispatch("checklist/duplicate", detach)
          .then(() => {
            this.$toast.success(this.Convert("Checklist Duplicated"));
            // Route to the duplicated checklist
            this.duplicating = false;
            this.$close(true);
          })
          .catch((error) => {
            this.duplicating = false;
            this.$whoops();
          });
    },
    async deleteChecklist() {
      if (
          await this.$confirm({
            title: this.Convert("Delete Checklist"),
            message: this.Convert("Are you sure you want to delete this checklist?"),
          })
      ) {
        this.$store
            .dispatch("checklist/deleteChecklist")
            .then(() => {
              this.$toast.success(this.Convert("Checklist Deleted"));
              this.$close(true);
            })
            .catch((error) => {
              this.$whoops();
            });
      }
    },
    syncAssetTypes() {
      this.syncing = true;
      this.$store
          .dispatch("checklist/syncAssetTypes")
          .then(() => {
            this.syncing = false;
            this.$toast.success(this.Convert("Asset Types Updated"));
          })
          .catch((error) => {
            this.$whoops();
            this.syncing = false;
          });
    },
    downloadAsExcel() {
      this.downloading = true;
      backend.downloadFile(
          `/api/checklist/${this.checklist.id}/download`,
          ({data}) => {
            download(
                data,
                `${this.checklist.name}.xlsx`,
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
            this.downloading = false;
          },
          (error) => (this.downloading = false)
      );
    },
  },

  computed: {
    ...mapGetters("checklist", ["checklist"]),
    ...mapGetters("assetType", ["assetTypeList"]),
  },
};
</script>
