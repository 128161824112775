<template>
  <page :title="Naming.Checks" icon="clipboard-check" v-bind="{ loading }" :loading-text="`Loading ${Naming.Checks}…`">

    <template #tools>
      <action-button v-if="$root.hasAbility('manage-checks', 'Billow\\Models\\Job')" class="is-primary is-rounded"
                     left-icon="plus" @click="createCheck">{{ Naming.Check }}
      </action-button>
    </template>

    <div class="box is-paddingless">
      <form class="p">
        <text-input
            class="m-1"
            classes="is-rounded has-addons is-medium"
            v-model="search"
            :placeholder="`Find a ${Naming.Check.toLowerCase()}…`">
          <template #right>
            <div class="is-flex is-flex-row is-flex-direction-row-reverse">
              <div class="is-flex-column">
                <action-button class="is-rounded is-medium" @click="runSearch" left-icon="search">
                  Search
                </action-button>
              </div>
              <div class="is-flex-column">
                <action-button class="is-medium" @click="clearSearch" v-if="search.length">
                  <icon icon="times"/>
                </action-button>
              </div>
            </div>
          </template>
        </text-input>
      </form>
      <pager class="pl" :pageable="checks" @nav="goToPage" :context="Naming.Check.toLowerCase()"/>
      <table class="table is-fullwidth is-hoverable">
        <tbody>
        <tr v-for="check in checks.data" :key="check.id">
          <td>
            <p class="is-flex flex-column">
            <span>
              <icon v-if="check.instant_fail" class="has-text-danger tooltip" data-tooltip="Instant Failure"
                    icon="exclamation-triangle"/>
              <a v-if="$root.hasAbility('manage-checks', 'Billow\\Models\\Job')"
                 class="has-text-link has-text-weight-bold" @click="editInspectionCheck(check)">
                <span>
                  {{ check.name }}
                  <icon class="has-text-danger" icon="lock" v-if="check.locked_checklists_count"/>
                </span>
              </a>
              <span v-else>
                {{ check.name }}
                <icon class="has-text-danger" icon="lock" v-if="check.locked_checklists_count"/>
              </span>
            </span>
              <small class="has-text-dark">{{ check.checklists_count }}
                {{ Naming.Checklist }}{{ check.checklists_count === 1 ? '' : 's' }}, last updated {{
                  check.updated_at | date('L LTS')
                }}</small>
            </p>
            <p>
              {{ check.task }}
            </p>
          </td>
          <td class="is-narrow">
            <div class="grid has-1-columns gap-1">
              <div class="tags has-addons is-one-line has-tooltip-top" data-tooltip="Image Rule">
                <span
                    class="tag is-icon is-rounded is-cool-blue-dark has-text-cool-blue-text-dark has-text-weight-medium"><icon
                    icon="camera"/></span>
                <span class="tag is-rounded flex-grow is-cool-blue">{{ check.image_rule | startCase }}</span>
              </div>
              <div class="tags has-addons is-one-line has-tooltip-top" data-tooltip="Comment Rule">
                <span
                    class="tag is-icon is-rounded is-cool-blue-dark has-text-cool-blue-text-dark has-text-weight-medium"><icon
                    icon="comment"/></span>
                <span class="tag is-rounded flex-grow is-cool-blue">{{ check.comment_rule | startCase }}</span>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <p class="has-text-centered" v-if="!checks.data.length">
        No {{ Naming.Checks.toLowerCase() }} found
      </p>
      <pager class="pl" :pageable="checks" @nav="goToPage" :context="Naming.Check.toLowerCase()"/>
    </div>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
import {newCheck, editCheck} from '@/modals'
import startCase from 'lodash/startCase'

export default {

  filters: {
    startCase
  },

  mounted() {

    this.handlePageRefresh()
  },

  data: () => ({
    loading: true,
    search: ''
  }),

  async created() {
    await this.loadChecks()
    this.loading = false
  },

  methods: {
    handlePageRefresh() {
      if(this.$route.query.search.length > 0) {

        this.search = this.$route.query.search
      }
    },
    loadChecks() {
      this.$scrollToTop()
      return this.$store.dispatch('check/loadChecks', {
        path: '/api' + this.$route.fullPath,
        params: this.$route.query
      })
    },
    editInspectionCheck(check) {
      this.$store.commit('check/setCheck', check)
      editCheck().then(() => {
        this.loadChecks(this.basePath)
      })
    },
    createCheck() {
      newCheck().then(() => {
        this.loadChecks(this.basePath)
      })
    },
    runSearch() {
      this.$router.push({
        name: 'check-index',
        query: {search: this.search}
      })
    },
    clearSearch() {
      this.search = ''
      this.$router.push({
        name: 'check-index',
        query: {}
      })
    },
    goToPage(path) {
      this.$router.push(path)
    }
  },

  computed: {
    ...mapGetters('check', [
      'checks'
    ])
  },

  watch: {
    '$route': 'loadChecks'
  }

}
</script>
