<template>
<page icon="qrcode" title="Barcode Batches" v-bind="{ loading }" loading-text="Loading barcodes…">

  <div slot="tools">
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <action-button
          left-icon="sync-alt"
          class="is-info is-rounded"
          @click="openGenerator">
          Request Barcodes
        </action-button>
      </div>
    </div>
  </div>

  <div class="box is-paddingless">
      <pager class="pl-1" :pageable="barcodes" @nav="goToPage" context="barcode"/>
      <div class="p-1">
        <div class="columns is-multiline">
          <div class="column is-3" v-for="batch in barcodes.data"  :key="batch.id">
            <batch-card v-bind="{batch}"/>
          </div>
        </div>
      </div>
      <p class="has-text-centered" v-if="!barcodes.data.length">
        No barcodes found
      </p>
      <pager class="pl-1" :pageable="barcodes" @nav="goToPage" context="barcode"/>
  </div>
</page>
</template>

<script>
import { mapGetters } from 'vuex'
import { barcodeRequest } from '@/modals'
import { barcode as backend } from '@/api'
import download from 'downloadjs'
import BatchCard from './BatchCard'

export default {

  components: {
    BatchCard
  },

  data: () => ({
    loading: true,
    batch_number: '',
    downloading: false,
    search: ''
  }),

  async created() {
    await this.loadBarcodes()
    this.loading = false
  },

  methods: {
    goToPage(path) {
      this.$router.push(path)
    },
    downloadBatch() {

      if(!String(this.batch_number).length) {
        this.$toast.error('Please select a batch')
        return
      }

      this.downloading = true

      backend.downloadBatchCsv(this.batch_number, ({data}) => {
        download(data, `${this.appName}-Barcode-Batch-${this.batch_number}.csv`, 'text/csv')
        this.downloading = false
      }, error => {
        this.downloading = false
      })
    },
    loadBarcodes() {
      this.$scrollToTop()
      return this.$store.dispatch('barcode/loadBarcodes', {
        path: '/api' + this.$route.fullPath,
        params: this.$route.query
      })
    },
    openGenerator() {
      barcodeRequest().then(() => {
        this.$store.dispatch('barcode/loadBatchNumbers')
        this.loadBarcodes('/api/barcodes')
      })
    },
    runSearch() {
      this.$router.push({
        name: 'barcode-index',
        query: { search: this.search }
      })
    },
    clearSearch() {
      this.search = ''
      this.$router.push({
        name: 'barcode-index',
        query: {}
      })
    }
  },

  computed: {
    ...mapGetters('barcode', [
      'barcodes'
    ]),
    appName() {
      return process.env.VUE_APP_NAME
    }
  },

  watch: {
    '$route': 'loadBarcodes'
  }
}
</script>
