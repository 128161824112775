<template>
  <page :title="Naming.Clients" icon="address-book" v-bind="{ loading }" :loading-text="`Loading Deleted ${Naming.Clients}…`">

    <div slot="tools">
      <form>
        <text-input
          classes="is-rounded"
          v-model="filters.search"
          @input="filterBy('search', ...arguments)"
          :placeholder="`Search ${Naming.Clients.toLowerCase()}`">
          <div class="buttons has-addons" slot="right">
            <action-button @click="searchClients" left-icon="search">Search</action-button>
            <router-link class="button is-rounded is-primary" :to="{
              name: 'client-admin-create'
            }">
              <icon icon="plus"/>
              <span>{{ Naming.Client }}</span>
            </router-link>
          </div>
        </text-input>
      </form>
    </div>

    <div class="box is-paddingless">
      <div class="columns">
        <div class="column">
          <table class="table is-fullwidth is-vcentered is-hoverable">
            <thead class="is-sticky">
            <tr>
              <th>{{ Naming.Company }}</th>
              <th class="has-text-centered">{{ Naming.Sites }}</th>
              <th class="has-text-centered">{{ Naming.Client }} {{ Naming.Users }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="client in clients.data" :key="client.id">
              <td class="flex-link">
                <avatar :path="client.logo_url" :name="client.legal_name"/>
                <span class="name">{{ client.legal_name }}</span>
              </td>
              <td class="counter has-text-centered is-highlighted has-left-border">{{ client.sites_count }}</td>
              <td class="counter has-text-centered is-highlighted">{{ client.users_count }}</td>
              <td>
                <action-button :disabled="recovering" @click="recoverClient(client)" class="is-small">Recover
                </action-button>
              </td>
            </tr>
            </tbody>
          </table>
          <p class="has-text-centered" v-if="!clients.data.length">
            No {{ Naming.Clients }} found
          </p>
          <div class="columns">
            <div class="column">
              <pager class="pl" :pageable="clients" @nav="loadClients" :context="Naming.Client.toLowerCase()"/>
            </div>
            <div class="column is-narrow">
              <router-link
                class="is-rounded button is-small mr-1"
                :to="{
                  name: 'client-admin-index',
                  params: {}
                }"
                v-if="$root.hasAbility('view-deleted-clients', 'Billow\\Models\\Client')"
              >
                <icon icon="undo-alt"/>
                <span>Show {{ Naming.Clients }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { mapGetters } from 'vuex'
import { utilities, mixins } from '@/mixins/js-helpers'

export default {

  mixins: [mixins.filterBy],

  data: () => ({
    loading: true,
    path: `/api/clients`,
    filters: {
      search: '',
      onlyTrashed: '1',
    },
    namespace: 'client',
    recovering: false,
  }),

  async created() {
    await this.loadClients(this.path + utilities.cleanQuery(this.filters))
    this.loading = false
  },

  methods: {
    loadClients(path) {
      console.log(path)
      this.$scrollToTop()
      return this.$store.dispatch('client/loadClients', path)
    },
    searchClients() {
      this.$store.dispatch(
        'client/loadClients',
        this.path + utilities.cleanQuery(this.filters)
      )
      this.$router.push({
        name: this.$options.name,
        query: this.filters
      })
    },
    recoverClient(client) {
      this.recovering = true
      this.$toast.info('Recovery started')
      this.$store.dispatch('client/recover', client.id)
        .then(() => {
          this.$toast.success('Recovery successful')
          this.$router.push({
            name: 'client-overview',
            params: {
              client: client.id
            }
          })
        })
        .catch(() => {
          this.recovering = false
          this.$toast.error('Something went wrong during the recovery process. Please contact support.')
        })
    }
  },

  computed: {
    ...mapGetters('client', [
      'clients'
    ])
  }
}
</script>
