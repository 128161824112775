<template>
<loader v-bind="{ loading }">
  <div class="box pl-2">
    <div>
      <h3 class="is-size-4 has-text-grey">New {{ Naming.Client }} {{ Naming.User }}</h3>
      <p>Note that {{ Naming.Client.toLowerCase() }} {{ Naming.Users.toLowerCase() }} are free and do not form part of your billing cycle.</p>
    </div>
    <form class="mt-1">
      <div class="columns">
        <div class="column">
          <text-input :error="$root.errors.name" required :value="user.name" @input="name">First Name</text-input>
        </div>
        <div class="column">
          <text-input :error="$root.errors.last_name" required :value="user.last_name" @input="lastName">Last Name</text-input>
        </div>
        <div class="column">
          <text-input :error="$root.errors.email" required :value="user.email" @input="email">Email</text-input>
        </div>
        <div class="column">
          <intl-phone
            :number="user.profile.mobile"
            :code="user.profile.dialing_code"
            @code="dialingCode"
            @number="mobile"/>
        </div>
        <div class="column is-narrow">
          <text-input :error="$root.errors['profile.position']" required :value="user.profile.position" @input="position">Position</text-input>
        </div>
      </div>

      <div class="mb-1">
        <data-selector
          description="Select the applicable timezone for the user"
          required
          :error="$root.errors.timezone"
          :value="user.timezone"
          :items="sortedTimezones"
          @input="updateTimezone"
          searchable>
          Timezone
        </data-selector>
      </div>

      <div class="mb-2">
        <client-user-site-access
          :siteList="siteList"
          :userClientSites="user.client_sites"
          :clientId="clientId">
        </client-user-site-access>
      </div>
      <div>
        <h4 class="title is-4 has-text-weight-light">Notification Channels</h4>
        <p class="mb-1">By default, notifications are turned off. Please select any applicable notification channels below.</p>
        <div class="grid gap-1">
          <notification-channel-check
            creating
            v-for="channel in notificationChannels" :key="channel.id"
            v-bind="{ channel }"
            @onToggle="toggleChannel(channel.id)"/>
        </div>
      </div>
      <div class="columns mt-1">
        <div class="column">
          <submit-button
            :working="working"
            @submit="store"
            class="is-success"
            left-icon="check">
            Create {{ Naming.User }}
          </submit-button>
        </div>
      </div>
    </form>
  </div>
</loader>
</template>

<script>
import { mapGetters, mapMutations, } from 'vuex'
import ClientUserSiteAccess from '../partials/ClientUserSiteAccess';

export default {

  components: {
    ClientUserSiteAccess
  },

  data: () => ({
    working: false,
    loading: true,
  }),

   beforeCreate() {
    this.$store.commit('user/clear')
  },

  created() {
    Promise.all([
      this.$store.dispatch('company/loadSubscribableNotificationTypesByRole', 'client'),
      this.$store.dispatch('site/loadClientSiteList', this.$route.params.client),
      this.$store.dispatch('list/loadTimezones')
    ]).then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapMutations('user', [
      'name',
      'lastName',
      'email',
      'roles',
      'mobile',
      'dialingCode',
      'position',
      'clientSites',
      'toggleSubscription',
      'toggleClientSite',
      'updateTimezone'
    ]),
    store() {
      if (this.user.client_sites.length === 0) {
        this.$toast.error('Please select at least one site.');
        return;
      }
      this.working = true
      this.$store.dispatch('user/storeClientUser', this.$route.params.client).then(password => {
        this.working = false
        this.$toast.success(this.Convert('User created successfully'))
        this.$router.push({
          name: 'client-users',
          params: { client: this.$route.params.client }
        })
      }).catch(error => {
        this.working = false
      })
    },
    toggleChannel(channelId) {
      this.$store.commit('user/toggleSubscription', channelId)
    },
  },

  computed: {
    ...mapGetters('user', [
      'user'
    ]),
    ...mapGetters('site', [
      'siteList'
    ]),
    ...mapGetters('role', [
      'roleList'
    ]),
    ...mapGetters('list', [
      'timezones'
    ]),
    ...mapGetters('company', [
      'notificationChannels'
    ]),
    clientId() {
      return this.$route.params.client.toString() 
    },
    sortedTimezones() {
      return this.timezones.map(timezone => {
        return { 
          value: timezone,
          label: timezone 
        }
      })
    }
  }

}
</script>
