<template>
  <div class="box">
    <h2 class="site-dash-heading mb-2">
      {{ client.legal_name }}
    </h2>
    <div class="columns"  v-if="overview.site_counts.total > 0 && overviewJobStats.failed >= 0">
      <div class="column">
        <section class="mt-1">
          <div class="columns is-multiline">
            <div class="column is-4">
              <p class="is-flex lh-1 align-center flex-column">
              <span
                  class="has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="overviewJobStats.failed"
              >{{ overviewJobStats.failed | abbreviateCount }}</span>
                <span class="is-size-6 has-text-weight-light">Failed {{ Naming.Jobs }}</span>
              </p>
            </div>
            <div class="column is-4">
              <p class="is-flex lh-1 align-center flex-column">
              <span
                  class="has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="overviewJobStats.passed"
              >{{ overviewJobStats.passed | abbreviateCount }}</span>
                <span class="is-size-6 has-text-weight-light">Passed {{ Naming.Jobs }}</span>
              </p>
            </div>
            <div class="column is-4">
              <p class="is-flex lh-1 align-center flex-column">
              <span
                  class="has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="overview.job_counts.completed"
              >{{ overview.job_counts.completed | abbreviateCount }}</span>
                <span class="is-size-6 has-text-weight-light">Completed {{ Naming.Jobs }}</span>
              </p>
            </div>
            <div class="column is-4">
              <p class="is-flex lh-1 align-center flex-column">
              <span
                  class="has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="overview.job_counts.open"
              >{{ overview.job_counts.open | abbreviateCount }}</span>
                <span class="is-size-6 has-text-weight-light">Open {{ Naming.Jobs }}</span>
              </p>
            </div>
            <div class="column is-4">
              <p class="is-flex lh-1 align-center flex-column">
              <span
                  class="has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="overview.job_counts.total"
              >{{ overview.job_counts.total | abbreviateCount }}</span>
                <span class="is-size-6 has-text-weight-light">Total {{ Naming.Jobs }}</span>
              </p>
            </div>
            <div class="column is-4">
              <p class="is-flex lh-1 align-center flex-column">
              <span
                  class="has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="overview.site_counts.total"
              >{{ overview.site_counts.total | abbreviateCount }}</span>
                <span class="is-size-6 has-text-weight-light">Total {{ Naming.Sites }}</span>
              </p>
            </div>
            <div class="column is-4">
              <p class="is-flex lh-1 align-center flex-column">
              <span
                  class="has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="overview.asset_counts.total"
              >{{ overview.asset_counts.total | abbreviateCount }}</span>
                <span class="is-size-6 has-text-weight-light">Total {{ Naming.Assets }}</span>
              </p>
            </div>
            <div class="column is-4">
              <p class="is-flex lh-1 align-center flex-column">
              <span
                  class="has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="overview.asset_counts.defective"
              >{{ overview.asset_counts.defective | abbreviateCount }}</span>
                <span class="is-size-6 has-text-weight-light">Defective {{ Naming.Assets }}</span>
              </p>
            </div>
            <div class="column is-4">
              <p class="is-flex lh-1 align-center flex-column">
              <span
                  class="has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="overview.asset_counts.ok"
              >{{ overview.asset_counts.ok | abbreviateCount }}</span>
                <span class="is-size-6 has-text-weight-light">OK {{ Naming.Assets }}</span>
              </p>
            </div>
            <div class="column is-4 align-center">
              <route name="overdue-inspection-index" :query="{ client: client.id }">
              <p class="is-flex lh-1 align-center flex-column">
              <span
                  class="has-tooltip-right is-size-2 has-text-weight-bold align-center" :data-tooltip="overview.inspection_counts.overdue"
              >{{ overview.inspection_counts.overdue | abbreviateCount }}</span>
                <span class="is-size-6 has-text-weight-light">Overdue {{ Naming.Inspections }}</span>
              </p> </route>
            </div>
          </div>
        </section>
      </div>
      <div class="column">
        <message>
          <div class="columns">
            <div class="column">
              <p>{{ Naming.Site }} Breakdown by Risk Classification</p>
              <p>
                Of the {{ Naming.Client.toLowerCase() }}’s
                {{ overview.site_counts.total }} {{ Naming.Sites.toLowerCase() }}, {{ overview.site_counts.with_risk }}
                {{ overview.site_counts.with_risk === 1 ? 'has' : 'have' }} one or more {{ Naming.Assets.toLowerCase() }} that carry risk.
              </p>
            </div>
          </div>
          <health-bars :counts="overview.risky_sites"/>
        </message>
      </div>
    </div>
    <div class="notification p-1 mt-1" v-else>{{ Naming.Site }} stats not available. This {{ Naming.Client.toLowerCase() }} doesn't have any {{ Naming.Sites.toLowerCase() }} yet.</div>
    <div v-if="overviewLatestJobs">
      <section class="mt-3">
        <div class="columns">
          <div class="column">
            <p class="has-text-weight-medium is-size-4">Latest {{ Naming.Jobs }}</p>
            <p v-show="overviewLatestJobs" class="has-text-weight-light">Below are the latest {{ Naming.Jobs.toLowerCase() }} for the {{ Naming.Client.toLowerCase() }}’s {{Naming.Sites.toLowerCase()}}.</p>
          </div>
          <div class="column">
            <div class="level-right">
              <div class="level-item">
                <date-picker
                    class="is-marginless"
                    style="width: 300px;"
                    placeholder="Select Date Range"
                    mode="range"
                    :value="inspectionDateRange"
                    @input="setInspectionDateRange">
                </date-picker>
                <action-button
                  class="ml is-hidden-touch"
                  :working="downloadingInspectionReport"
                  @click="downloadInspectionReport"
                  left-icon="download">
                  Download
                </action-button>
              </div>
            </div>
          </div>
        </div>
        <template>
          <table class="table is-hoverable is-fullwidth">
            <thead>
            <tr>
              <th></th>
              <th>{{ Naming.Site }}</th>
              <th class="has-text-right">Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="job in overviewLatestJobs" :key="job.id">
              <td>
                <link-job-handler v-bind="{ job }">#{{ job.number }} - {{ job.type.description }}</link-job-handler>
              </td>
              <td>
                <link-site-manager :site="job.site"/>
              </td>
              <td class="has-text-right">
                <current-status :latest-status="job.latest_status"/>
              </td>
            </tr>
            </tbody>
          </table>
        </template>

      </section>
    </div>
    <div class="notification p-1 mt-1" v-else>
      Latest {{ Naming.Jobs.toLowerCase() }} not available. This {{ Naming.Client.toLowerCase() }} doesn't have any {{Naming.Sites.toLowerCase()}} yet.
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {client as backend} from '@/api'
import download from 'downloadjs'
import HealthBars from '../partials/HealthBars';
import moment from "moment";
import abbreviateCount from "../../../mixins/js-helpers/src/filters/abbreviateCount";

export default {
  components: {
    HealthBars
  },

  data: () => ({
    chartOptions: {
      cutoutPercentage: 85,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      }
    },
    inspectionDateRange: '',
    downloadingInspectionReport: false,
  }),

  beforeCreate() {
    this.$store.dispatch('client/loadOverview', this.$route.params.client)
    this.$store.dispatch('client/loadOverviewLatestJobs', this.$route.params.client)
    this.$store.dispatch('client/loadOverviewJobStats', this.$route.params.client)
    this.inspectionDateRange = moment().startOf('month').format('DD-MM-YYYY') + ' to ' + moment().format('DD-MM-YYYY')
  },

  computed: {
    ...mapGetters('client', [
      'client',
      'overview',
      'overviewLatestJobs',
      'overviewJobStats'
    ]),
  },
  methods: {
    abbreviateCount,
    setInspectionDateRange(value) {
      this.inspectionDateRange = value
    },
    downloadInspectionReport() {
      this.downloadingInspectionReport = true
      backend.downloadInspectionReportByDateRange({
        clientId: this.client.id,
        date_range: this.inspectionDateRange
      }, ({data}) => {
        this.downloadingInspectionReport = false
        download(data, `#${this.client.id}-${this.client.legal_name}-inspections-${this.inspectionDateRange}.pdf`, 'application/pdf')
      }, error => {
        this.$whoops()
        this.downloadingInspectionReport = false
      })
    },
  }

}
</script>
