<template>
<page icon="sad-cry" title="Page Not Found">
  <div class="box p-3">
    <p>Whoops looks like we having troubling finding the page you're after.</p>
    <router-link class="button is-info mt-2" :to="{ name: 'dashboard' }">
      <icon icon="home"/>
      <span>Go Home</span>
    </router-link>
  </div>
</page>  
</template>

<script>
export default {

}
</script>