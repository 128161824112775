<template>
  <article class="sortable-selector">

    <!-- Search or Create Checks -->
    <div class="is-sticky-header">
      <server-data-selector
        :disabled="loading || checklist.locked || !canEditChecks"
        :data-tooltip="canEditChecks ? null : `You cannot add ${Naming.Checks.toLowerCase()} to a ${Naming.Checklist.toLowerCase()} with completed ${Naming.Inspections.toLowerCase()}`"
        searchable
        ref="checkSearch"
        :items="filteredChecklists"
        @input="pushCheck"
        :prompt-label="`Add an existing ${Naming.Check.toLowerCase()}`"
        classes="is-rounded has-tooltip-multiline"
        value-key="id"
        label-key="name"
        left-icon="search"
        :on-type="searchChecklists">
        <action-button
          slot="right"
          :data-tooltip="canEditChecks ? null : `You cannot add ${Naming.Checks.toLowerCase()} to a ${Naming.Checklist.toLowerCase()} with completed ${Naming.Inspections.toLowerCase()}`"
          :disabled="checklist.locked || !canEditChecks"
          @click="createQuickCheck"
          class="is-rounded is-cool-blue-darker has-tooltip-multiline"
          left-icon="plus">
          New {{ Naming.Check }}
        </action-button>
        <template #item="{ item }">
          <div class="flex-grow">
            <p class="has-text-weight-bold">{{ item.name }}</p>
            <p class="has-text-weight has-text-cool-blue-text">{{ item.task | truncate(100) }}</p>
          </div>
          <span class="tag is-rounded is-warning has-text-weight-bold" v-if="item.instant_fail">Instant Fail</span>
          <span class="tag is-rounded is-cool-blue ml" v-if="item.checklists_count">on {{ item.checklists_count }} {{ 'other checklist' | plural(item.checklists_count) }}</span>
        </template>
      </server-data-selector>
    </div>

    <!-- No checkpoints -->
    <notification class="is-cool-blue has-text-centered p-2 is-marginless" v-if="!checklist.checks.length">
      <p class="has-text-weight-bold">No {{ Naming.Checks }} Added</p>
      <p>This {{ Naming.Checklist.toLowerCase() }} doesn’t have any {{Naming.Checks.toLowerCase()}} yet.</p>
      <p>Get started by selecting an existing {{Naming.Check.toLowerCase()}}, or creating a new {{Naming.Check.toLowerCase()}}.</p>
    </notification>

    <!-- Sort and remove checklists -->
    <draggable
      handle="#handle"
      :animation="200"
      :value="checklist.checks"
      @input="setChecks"
      group="checklists">
      <transition-group>
        <checkpoint
          v-for="(check, index) in checklist.checks"
          :key="check.id"
          :is-locked="checklist.locked"
          :can-delete="canEditChecks"
          v-bind="{ check, index }"
          @onCheckUpdated="updateChecks"
          @onRemoveCheck="pullCheck"/>
      </transition-group>
    </draggable>

  </article>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { newCheck } from '@/modals'

import Checkpoint from './Checkpoint'

import differenceBy from 'lodash/differenceBy'
import find from 'lodash/find'
import Search from '@/utils/search'

export default {

  components: {
    Checkpoint,
  },

  props: {
    canEditChecks: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    loading: true,
  }),

  created() {
    Search.searchByUri('', 'check/searchChecksList', this.$store, 0),
    this.loading = false
  },

  methods: {
    ...mapMutations('checklist', [
      'setChecks',
      'addCheck',
      'removeCheck',
    ]),

    async createQuickCheck() {
      const check = await newCheck()
      if (check) {
        this.addCheck(check)
        this.saveChecklist()
      }
    },

    updateChecks(isChanged) {
      if (isChanged) {
        this.$store
            .dispatch("checklist/loadChecklist", this.$route.params.checklist)
      }
    },

    pushCheck(checkId) {
      this.$refs.checkSearch.search = ''
      this.$refs.checkSearch.selected = ''
      const check = find(this.checks, { id: checkId })
      this.addCheck(check)
    },

    async pullCheck(index) {
      const { title, message, confirmText } = this.$lang.check.confirmRemove;
      const convertedTitle = await this.Convert(title)
      const convertedMessage = await this.Convert(message)
      const convertedConfirmText = await this.Convert(confirmText)
      if (await this.$confirm({
        title: convertedTitle,
        message: convertedMessage,
        confirmText: convertedConfirmText,
        confirmClass: 'is-danger'
      })) {
        this.removeCheck(index)
      }
    },

    saveChecklist() {
      this.$store
          .dispatch("checklist/save")
          .then((response) => {
            this.$store
                .dispatch("checklist/loadChecklist", this.$route.params.checklist)
          })
          .catch((error) => {
            this.$whoops();
          });
    },
    searchChecklists(text) {
      Search.debouncedSearchByUri(text, 'check/searchChecksList', this.$store)
    },

  },

  computed: {
    ...mapState('checklist', [
      'checklist',
    ]),
    ...mapState('check', {
      checks: 'checkList',
    }),

    filteredChecklists() {
      return differenceBy(this.checks, this.checklist.checks, 'id')
    },
  },

}
</script>
