<template>
<loader v-bind="{ loading }">
  <div class="box">
    <level :title="Naming.Users">
      <div slot="right">
        <router-link :to="{
          name: 'create-client-user',
          params: {
            client: client.id
          }
        }">
        <icon icon="plus"/>
        <span>{{ Naming.User }}</span>
        </router-link>
      </div>
    </level>

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          <th>Mobile</th>
          <th>Email</th>
          <th>Position</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users.data" :key="user.id">
          <td>
            <router-link :to="{
              name: 'edit-client-user',
              params: {
                user: user.id
              }
            }">
              {{ user.full_name }}
            </router-link>
          </td>
          <td>{{ user.profile.formatted_mobile }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.profile.position }}</td>
          <td><yes-no :value="user.active"/></td>
        </tr>
      </tbody>
    </table>
    <p v-if="!users.data.length" class="has-text-centered p-2">
      No {{ Naming.Users.toLowerCase() }} to display.
    </p>
    <pager :pageable="users" @nav="goToPage"/>
  </div>
</loader>
</template>

<script>
import { mapGetters } from 'vuex'


export default {

  data: () => ({
    loading: true,
    basePath: ''
  }),

  async created() {
    this.basePath = `/api/client/${this.$route.params.client}/users`
    await this.loadUsers(this.basePath)
    this.loading = false
  },

  methods: {
    loadUsers() {
      return this.$store.dispatch('user/loadUsers', {
        path: '/api' + this.$route.fullPath
      })
    },
    goToPage(page) {
      this.$router.push(page)
    }
  },

  computed: {
    ...mapGetters('client', [
      'client'
    ]),
    ...mapGetters('user', [
      'users'
    ]),
  },

  watch: {
    $route: 'loadUsers'
  }

}
</script>
