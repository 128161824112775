<template>
  <div>
    <h4 class="is-4 has-text-weight-bold">Accessible {{ Naming.Sites }} <span class="has-text-danger">*</span></h4>
    <small class="mb-2 has-text-grey">Select the {{ Naming.Sites.toLowerCase() }} you would like this {{ Naming.User.toLowerCase() }} to be able to access:</small>
    <form class="flex-grow">
      <text-input 
        classes="level-item is-rounded" 
        v-model="filters.search"
        @keydown.enter.prevent="searchSites"  
        :placeholder="`Search ${Naming.Sites.toLowerCase()}…`">
        <div slot="right">
          <action-button
            v-if="filters.search"
            slot="right"
            class=""
            @click="clearFilter()">
            <icon icon="times"/>
          </action-button>
          <action-button @click="searchSites" left-icon="search" class="is-rounded">Search</action-button>
        </div>
      </text-input>
      <div class="buttons">
        <action-button @click="selectAllClientSites()" class="is-rounded is-success is-inverted">Select all</action-button>
        <action-button @click="deselectAllClientSites()" class="is-rounded is-danger is-inverted">Deselect all</action-button>
      </div>
    </form>
    <div class="grid has-2-columns column-gap-1">
      <a v-for="site in clientUserSites.data" :key="site.id" @click="toggleClientSite(site)"
        class="is-flex align-center selectable-item" :class="{ 'is-selected': userHasAccessToSite(site) }">
        <icon class="mr-1" icon="check-circle" :type="userHasAccessToSite(site) ? 'fas' : 'far'" />
        <div class="is-flex flex-grow align-center">
          <span class="has-text-weight-medium" :class="{ 'has-text-grey': !userHasAccessToSite(site) }">{{
            site.name
          }}</span>
          <span class="has-text-grey-light has-text-right flex-shrink-none">&nbsp;— {{
            site.zones_count || 'No'
          }} {{ 'zones' | plural(site.zones_count) }}</span>
        </div>
      </a>
    </div>
    <pager :pageable="clientUserSites" @nav="goToPage" :context="Naming.Site.toLowerCase()" />
  </div>
</template>
  
<script>
import findIndex from 'lodash/findIndex'
import { mapState } from 'vuex';
import {common as backend} from "@/api";

export default {
  props: {
    siteList: Array,
    userClientSites: Array,
    clientId: String,
  },


  data() {
    return {
      loading: true,
      namespace: 'sites',
      path: '',
      filters: {
        search: ''
      }
    };
  },
  async created() {
  await this.loadSites({
    clientId: this.clientId,
    query: this.$route.query
  });
  this.loading = false;
},

  methods: {
    toggleClientSite(site) {
      this.$store.commit('user/toggleClientSite', site)
    },
    userHasAccessToSite(site) {
      return findIndex(this.userClientSites, { id: site.id }) >= 0
    },
    loadSites(path) {
      return this.$store.dispatch(
        'site/loadClientUserSites',
        path
      )
    },
    goToPage(path) {
      this.loading = true
      backend.loadPath({
        path: path,
      }, ({ data }) => {
        this.$store.commit('site/setClientUserSites', data)
        this.loading = false
      }, error => {
        this.$whoops()
        this.loading = false
      })
    },
    searchSites() {
      const newQuery = { ...this.$route.query };
      newQuery.search = this.filters.search;
      this.$router.replace({
        name: this.$options.name,
        query: newQuery,
      });
      this.loadSites({
        clientId: this.clientId,
        query: newQuery,
      });
    },
    clearFilter() {
      this.filters.search = ''
      this.searchSites()
    },
    selectAllClientSites(){
        this.$store.commit('user/addClientSitesToClientUserSites', this.siteList)
    },
    deselectAllClientSites(){
        this.$store.commit('user/removeClientSitesFromClientUserSites', this.siteList)
    },
  },
  computed: {
    ...mapState('site', [
      'clientUserSites'
    ]),
  }
};
</script>
  
  