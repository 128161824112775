<template>
  <section class="page">
    <!-- Header and Buttons/Tools -->
    <nav>
      <h1>
        <a @click="$root.app.toggleSidebar" class="is-hidden-desktop is-flex align-center">
          <icon class="page-icon-color" icon="bars"/>
        </a>
        <icon v-bind="{ icon }" :type="iconType"/>
        <slot name="title" v-if="$slots.title"/>
        <span v-text="loading ? 'Loading…' : title" v-else></span>
        <span v-if="badge" class="tag ml-1 is-primary is-rounded has-text-white" v-text="badge"/>
      </h1>
      <div class="tools" v-if="$slots.tools && !loading">
        <slot name="tools"/>
      </div>
    </nav>

    <!-- Page content -->
    <div class="page-content" :class="contentClass">
      <slot v-if="!loading" />
      <div class="loading-container" v-else>
        <loader :text="loadingText" />
      </div>
    </div>
  </section>
</template>

<script>
export default {

  props: {
    title: String,
    badge: String,
    icon: String,
    loadingText: String,
    contentClass: String,
    loading: {
      type: Boolean,
      default: false,
    },
    iconType: {
      type: String,
      default: 'fas',
    }
  }
}
</script>
