var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({},'loader',{ loading: _vm.loading },false),[_c('div',{staticClass:"box"},[_c('level',{attrs:{"title":_vm.Naming.Users}},[_c('div',{attrs:{"slot":"right"},slot:"right"},[_c('router-link',{attrs:{"to":{
          name: 'create-client-user',
          params: {
            client: _vm.client.id
          }
        }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v(_vm._s(_vm.Naming.User))])],1)],1)]),_c('table',{staticClass:"table is-fullwidth"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Mobile")]),_c('th',[_vm._v("Email")]),_c('th',[_vm._v("Position")]),_c('th',[_vm._v("Active")])])]),_c('tbody',_vm._l((_vm.users.data),function(user){return _c('tr',{key:user.id},[_c('td',[_c('router-link',{attrs:{"to":{
              name: 'edit-client-user',
              params: {
                user: user.id
              }
            }}},[_vm._v(" "+_vm._s(user.full_name)+" ")])],1),_c('td',[_vm._v(_vm._s(user.profile.formatted_mobile))]),_c('td',[_vm._v(_vm._s(user.email))]),_c('td',[_vm._v(_vm._s(user.profile.position))]),_c('td',[_c('yes-no',{attrs:{"value":user.active}})],1)])}),0)]),(!_vm.users.data.length)?_c('p',{staticClass:"has-text-centered p-2"},[_vm._v(" No "+_vm._s(_vm.Naming.Users.toLowerCase())+" to display. ")]):_vm._e(),_c('pager',{attrs:{"pageable":_vm.users},on:{"nav":_vm.goToPage}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }