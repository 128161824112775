<template>
  <page :title="`${Naming.Checklist} Manager`" icon="clipboard-list" v-bind="{ loading }">
    <template #tools>
      <div class="buttons">
        <action-button
            :disabled="working || checklist.locked"
            left-icon="calendar"
            icon-class="has-text-primary"
            class="is-white is-rounded"
            @click="editingVersions = !editingVersions"
        >
          {{ Naming.Checklist }} Versions
        </action-button>
        <action-button
          :disabled="working || checklist.locked"
          left-icon="cog"
          icon-class="has-text-primary"
          class="is-white is-rounded"
          @click="checklistTools"
        >
          {{ Naming.Checklist }} Tools
        </action-button>
        <submit-button
          v-bind="{ working }"
          :disabled="checklist.locked"
          @submit="saveChecklist"
          class="is-success is-rounded has-text-weight-bold"
          left-icon="check"
        >
          Save {{ Naming.Checklist }}
        </submit-button>
      </div>
    </template>

    <template #default>
      <message v-if="checklist.locked" class="is-danger" title="Locked">
        This {{ Naming.Checklist.toLowerCase() }} has been locked. As such you cannot make changes to this
        {{ Naming.Checklist.toLowerCase() }}. Please contact support if you would like to make changes to
        this {{ Naming.Checklist.toLowerCase() }}. Furthermore {{ Naming.Checks.toLowerCase() }} associated with this {{ Naming.Checklist.toLowerCase() }}
        will also be locked.
      </message>
      <div class="grid gap-1 has-8-columns">
        <div class="grid gap-1 mb-3 is-5-columns" :class="{ 'is-8-columns' : !editingVersions }" >
          <!-- Information -->
          <div class="box is-marginless">
            <h2 class="box-title">{{ Naming.Checklist }} Information</h2>
            <div class="grid has-6-columns gap-1">
              <!-- Name -->
              <text-input
                class="is-2-columns"
                :error="$root.errors.name"
                required
                :value="checklist.name"
                @input="name"
                :description="`Give your ${Naming.Checklist.toLowerCase()} a descriptive name.`"
              >
                Name
              </text-input>

            <!-- Frequency -->
            <article class="is-2-columns">
              <formatted-text-input
                :error="$root.errors.frequency"
                :value="checklist.frequency"
                :options="{
                  numeral: true,
                  numeralDecimalScale: 0,
                  numeralThousandsGroupStyle: 'none',
                }"
                @input="updateFrequency"
                :description="`How often will this ${Naming.Checklist.toLowerCase()} need to be performed?`"
              >
                Frequency
                <static-button slot="right">
                  <template v-if="checklist.frequency < 1"
                    >(no repeat instructions)</template
                  >
                  <template v-else>days</template>
                </static-button>
              </formatted-text-input>
            </article>

            <!-- Pass Percentage -->
            <formatted-text-input
              class="is-2-columns"
              required
              :options="{
                numeral: true,
                numeralDecimalScale: 0,
                numeralThousandsGroupStyle: 'none',
              }"
              :error="$root.errors.pass_percentage"
              :value="checklist.pass_percentage"
              @input="passPercentage"
              hint="Default is 80%"
              :description="`Set the minimum pass rate for this ${Naming.Checklist.toLowerCase()}.`">
                Pass Percentage
                <static-button slot="right">%</static-button>
            </formatted-text-input>

            <!-- Toggle Requires a Signature -->
              <switch-input
                class="is-2-columns"
                :description="`When required, ${Naming.Checklists.toLowerCase()} of this type will require the ${Naming.Technician.toLowerCase()} to provide their signature before closing it off.`"
                classes="is-large"
                :value="checklist.requires_signature"
                @input="toggleSignatureRequirement"
              >
                Requires a {{ Naming.Technician }} Signature
              </switch-input>

              <switch-input
                class="is-2-columns"
                :description="`When required, ${Naming.Checklists.toLowerCase()} of this type will require an approval signature before closing it off.`"
                classes="is-large"
                :value="checklist.requires_approver_signature"
                @input="toggleApproverSignatureRequirement"
              >
                Requires an Approval Signature
              </switch-input>

              <!-- Toggle report images -->
              <switch-input
                  class="is-2-columns"
                  :description="`${Naming.Inspection.toLowerCase()} report evidence images inline with answer instead of at end of report.`"
                  :hint="`Evidence images are displayed at the end of the ${Naming.Inspection.toLowerCase()} report by default. Selecting this option will move the images inline with the ${Naming.Check.toLowerCase()} answer.`"
                  classes="is-large"
                  :value="checklist.inspection_report_images_inline"
                  @input="toggleInspectionReportImages">
                {{ Naming.Inspection }} Report Evidence Images Inline
              </switch-input>

            <switch-input
                v-if="checklist.report_layout"
                class="is-2-columns"
                :description="`${Naming.Inspection} report to use custom report.`"
                :hint="`These ${Naming.Inspections.toLowerCase()} will use a custom built report if true.`"
                classes="is-large"
                :value="checklist.use_custom_report"
                @input="toggleCustomReport">
              Use custom {{ Naming.Inspection }} reports for this {{ Naming.Checklist }}
            </switch-input>
            </div>
          </div>

          <!-- Checklists -->
          <div class="box">
            <h2 class="box-title checks-box-title">
              {{ checklist.checks.length }}
              {{ Naming.Check | plural(checklist.checks.length) }}
              <span class="has-text-danger has-text-left">*</span>
            </h2>
            <edit-checklist-select-checkpoints
              :can-edit-checks="canEditChecks">
            </edit-checklist-select-checkpoints>
          </div>
        </div>

        <!-- Checklist Versions-->
        <transition name="slide">
          <div class="box is-3-columns slidingDiv"  style="max-height: 800px; overflow-y: auto;" v-if="editingVersions">
            <div class="is-flex justify-between">
              <h2 class="box-title checks-box-title">{{ Naming.Checklist }} Versions</h2>
              <icon @click="editingVersions = false" icon="eye-slash" class="hover-bold has-text-grey" />
            </div>
            <checklist-version v-for="version in comparedChecklistVersions" :key="version.version" :version="version" />
          </div>
        </transition>
      </div>
    </template>
  </page>
</template>

<script>
import { checklistTools } from "@/modals"
import {mapState, mapMutations, mapGetters} from "vuex"
import EditChecklistSelectCheckpoints from "./partials/EditChecklistSelectCheckpoints"
import ChecklistVersion from "@/views/checklist/partials/ChecklistVersion.vue";
import _ from 'lodash';

export default {
  components: {
    ChecklistVersion,
    EditChecklistSelectCheckpoints,
  },

  data: () => ({
    working: false,
    loading: true,
    canEditChecks: true,
    saved: false,
    frequencyChanged: false,
    editingVersions: false,
  }),

  computed: {
    ...mapState("checklist", ["checklist"]),
    ...mapState("checklist", ["currChecklist"]),
    ...mapGetters("checklist", ["comparedChecklistVersions"]),
    ...mapGetters("checklist", ["checklistVersions"]),
  },

  async created() {
    Promise.all([
      this.$store.dispatch("checklist/loadChecklist", this.$route.params.checklist),
      this.getVersions()
    ]).then(() => this.loading = false);

    this.canEditChecks = !this.checklist.locked
  },

  destroyed() {
    this.$store.commit("checklist/clear");
  },

  methods: {
    ...mapMutations("checklist", [
      "addCheck",
      "name",
      "frequency",
      "passPercentage",
      "toggleUpdatesNextInspection",
      "toggleSignatureRequirement",
      "toggleApproverSignatureRequirement",
      "toggleInspectionReportImages",
      "toggleCustomReport",
      "setChecks",
      "duplicate",
    ]),

    async checklistTools() {
      if (await checklistTools()) {
        this.saved = true
        this.$router.push({ name: "checklist-index" });
      }
    },

    saveChecklist() {
      const { checklist, currChecklist } = this.$store.state.checklist;
      if (!_.isEqual(checklist, currChecklist)) {
        if (this.frequencyChanged) {
          this.$alert('Notice', this.Convert('The new checklist frequency will only be applied after the next inspection'))
          this.frequencyChanged = false
        }
        this.saved = true
        this.working = true
        this.$store.dispatch("checklist/save").then(() => {
          this.$toast.success(this.Convert(this.$lang.checklist.saved))
          this.getVersions()
        }).catch((error) => {
          this.$whoops()
        }).finally(() => {
          this.working = false;
        })
      } else {
        this.$router.back()
      }

    },

    async getVersions() {
      await this.$store.dispatch("checklist/loadChecklistVersions", this.$route.params.checklist)
    },

    async confirmLeave() {
      return this.$confirm({
            title: "Warning",
            message: `Leave without saving?`,
            confirmText: "Leave Page",
            cancelText: "Stay Here",
            confirmClass: 'is-warning',
            cancelClass: 'is-info',
          })
          .catch(() => {
            this.$whoops()
          })
    },

    /** @param {number} frequency */
    async updateFrequency(frequency) {
      let oldFrequency = this.checklist.frequency
      this.checklist.frequency = frequency
      this.frequencyChanged = oldFrequency !== frequency
    },
  },

  async beforeRouteLeave(to, from, next) {
    const { checklist, currChecklist } = this.$store.state.checklist;
    const hasUnsavedChanges = !_.isEqual(checklist, currChecklist);
    if (!hasUnsavedChanges || this.checklist.locked) {
      next()
    } else {
      const approved = await this.confirmLeave()
      if (approved) {
        next()
      }
    }
  }
};
</script>

<style>
/* Define the transition */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(100%);
}

.sliding-div {
  background-color: #f0f0f0;
  padding: 20px;
  border: 1px solid #ddd;
}
</style>
